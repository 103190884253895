import React, { FC, useEffect, useMemo, useState } from "react";
import MovieDescription from "../../../../../components/TicketBooking/MovieDescription";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import {
  IFormatedTicketType,
  ISeatLayout,
  ITicketType,
  MODAL_TYPE,
  TICKET_FLOW_TYPES,
} from "../../../../../models/tickets";
import { API_REQUEST_TYPE, IMAGE_URL_CONFIG, URLS } from "../../../../../constants/url";
import {
  ALLOWED_MEMBER_VALIDATE,
  BRANDS,
  COUNTRY,
  EXCLUDE_BOOKING_FEE_SESSION,
  PREMIUM_SERCHRG_ATTR,
} from "../../../../../constants/app";
import "./seatSelection.scss";
import {
  setPopupInfo,
  setReinitializeScroll,
} from "../../../../../store/application/action";
import { TICKET_FLOW } from "../../../../../constants/ticket";
import { calculateServiceCharge, checkSeat, checkSeatValidation, generateUsersessionId } from "../../../../../utils/tickets";
import { formatUserDetails } from "../../../../../utils/formatter";
import {
  addTicketSuccess,
  cancelOnlyTicketAction,
  setModal,
  updateSession,
} from "../../../../../store/ticketBooking/action";
import { addTicketApi } from "../../../../../services/films";
import { setUpModalHeaderText } from "../../../../../store/foodAndBeverage/action";
import { toast } from "react-toastify";
import { ISharedUserSession } from "../../../../../store/ticketBooking/type";
import { useLocation } from "react-router";
import SwapFilter from "../../../../../components/UI/Filters/SwapFilter";
import { memberValidationApi } from "../../../../../services/auth";
import { memberValidationSuccess } from "../../../../../store/auth/action";
import moment from "moment";
import Button from "../../../../../components/UI/Button";

interface ISeatSelectionProps {
  onCancel: (error?: string) => void;
}

const SeatSelection: FC<ISeatSelectionProps> = ({ onCancel }) => {
  const {
    countryId,
    modalType,
    nextModal,
    ticketBooking,
    settings,
    films,
    showCovid,
    userDetails,
    isLoggedIn,
    hasGL,
    ticketDetails,
    prevModal,
    sharedUserSession,
    seatSwapDetails,
    brandId,
    currentCinema,
    infoPopup,
    ticketLessFb,
    memberDetail,
    serviceChargeConfig
  } = useSelector((state: RootState) => ({
    countryId: state.applicationReducer.countryId,
    modalType: state.ticketBookingReducer.modal?.type,
    nextModal: state.ticketBookingReducer.modal?.nextModal,
    ticketBooking: state.ticketBookingReducer,
    settings: state.applicationReducer.settings,
    films: state.ticketBookingReducer.films,
    showCovid: state.ticketBookingReducer.showCovid,
    userDetails: state.authReducer.userDetails,
    isLoggedIn: state.authReducer.isLoggedIn,
    hasGL: state.ticketBookingReducer.hasGL,
    ticketDetails: state.ticketBookingReducer.ticketDetails,
    prevModal: state.ticketBookingReducer.modal.prevModal,
    sharedUserSession: state.ticketBookingReducer.sharedUserSession,
    seatSwapDetails: state.ticketBookingReducer.seatSwapDetails,
    brandId: state.applicationReducer.brandId as BRANDS,
    currentCinema: state.applicationReducer.currentCinema,
    infoPopup: state.applicationReducer.infoPopup,
    ticketLessFb: state.foodBeverageReducer.ticketLessFb,
    memberDetail: state.authReducer.memberDetail,
    serviceChargeConfig: state.ticketBookingReducer.serviceChargeConfig
  }));

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [seatLayout, setSeatLayout] = useState<Array<ISeatLayout[]>>([]);
  const [selectedSeat, setSelectedSeat] = useState<Array<any>>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (
      ticketDetails &&
      ticketDetails.selectedSeats &&
      ticketDetails.selectedSeats.length > 0 &&
      selectedSeat.length === 0 &&
      seatLayout.length > 0
    ) {
      // setSelectedSeat(ticketDetails.selectedSeats);
      // setCount(
      //   ticketDetails.selectedSeats && ticketDetails.selectedSeats.length
      //     ? ticketDetails.selectedSeats.length
      //     : 0
      // );

      // setSeatLayout(ticketDetails.seatLayout);
    }
  }, [ticketDetails, selectedSeat, seatLayout]);

  useEffect(() => {
    if (
      ticketBooking?.seatLayout &&
      ticketBooking?.seatLayout?.length > 0 &&
      ticketBooking.ticketType
    ) {
      let seatLayoutCopy = JSON.parse(JSON.stringify(ticketBooking.seatLayout));
      let countCopy = 0;
      let selectSeatCopy: Array<any> = [];

      if (
        ticketDetails &&
        ticketDetails.selectedSeats &&
        ticketDetails.selectedSeats.length > 0
      ) {
        selectSeatCopy = ticketDetails.selectedSeats;
        countCopy = ticketDetails.selectedSeats.length;
        seatLayoutCopy = seatLayoutCopy.map((v: ISeatLayout[]) => {
          return v.map((s: ISeatLayout) => {
            if (
              selectSeatCopy.find(
                (seat: ISeatLayout) => seat.SeatId === s.seatId
              )
            ) {
              s["isBooked"] = true;
              s.isSelected = true;
            }
            return s;
          });
        });

        setSelectedSeat(selectSeatCopy);
      }
      setSeatLayout(seatLayoutCopy);
      setCount(countCopy);
      setErrorMessage("");
      dispatch(setReinitializeScroll(true));
    } else {
      onCancel("Tickets not available for this session");
      return;
    }
  }, [ticketBooking.seatLayout, ticketBooking.ticketType]);

  const covidSeatStatus = useMemo(() => {
    return (
      seatLayout.filter((layoutResult) => {
        return layoutResult.some(
          (layoutChlid) => layoutChlid.seatType === "Covid19"
        );
      }).length > 0
    );
  }, [seatLayout]);

  const isMember = useMemo(()=>{
    return memberDetail && memberDetail.LoyaltySessionToken?true: false;
  },[memberDetail]);

  const { bookingFees, total, price } = useMemo(() => {
    if (
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
    ) {
      const bookingFees = ticketBooking.ticketDetails?.bookingFees
        ? Number(ticketBooking.ticketDetails?.bookingFees)
        : 0;
      const total = ticketBooking.ticketDetails?.total
        ? Number(ticketBooking.ticketDetails?.total)
        : 0;

      const price = ticketBooking.ticketDetails?.price
        ? Number(ticketBooking.ticketDetails?.price)
        : 0;
      return { bookingFees, total, price };
    } else {
      const serviceCharge = calculateServiceCharge({
        hasGL,
        brandId,
        films,
        serviceCharge: serviceChargeConfig,
        isMember
      })
      const bookingFees =
        selectedSeat.length * serviceCharge;

      const price =
        ticketBooking &&
        ticketBooking.ticketType &&
        ticketBooking.ticketType.length > 0
          ? Number(ticketBooking.ticketType[0].Price)
          : 0;
      const total =
        selectedSeat.length * price +
        selectedSeat.length * serviceCharge

      return { bookingFees, total, price };
    }
  }, [selectedSeat, ticketBooking.ticketDetails]);

  useEffect(()=>{
    if(infoPopup && infoPopup.type === 'Companion' && infoPopup.data && infoPopup.data.companion){
      const { data: {event, parentIndex, childIndex}} = infoPopup;
      dispatch(setPopupInfo({ open: false, type: "", data:{} }));
      onClickSeat(event, parentIndex, childIndex);
    }
  },[infoPopup])

  const onClickSeat = (event: any, parentIndex: number, childIndex: number) => {
    setErrorMessage("");
    if (
      event &&
      (selectedSeat.length >= 10 ||
        ((modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
          modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB) &&
          selectedSeat.length >=
            Number(
              ticketBooking?.ticketDetails?.ticketTypes
                ? ticketBooking?.ticketDetails?.ticketTypes?.reduce(
                     (acc: number, v: ITicketType) => (v.isPackageTicket?(acc+(v.qty! * v?.isPackageContent?.Quantity!)):(acc + v.qty!)),
                    0
                  )
                : 0
            )))
    ) {
      setErrorMessage(
        modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
          modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
          ? `You have already selected all of your seat(s).`
          : `You have selected the max tickets`
      );

      return;
    }
    const seatLayoutCopy = [...seatLayout];
    const seatSelected = [...selectedSeat];
    const seatObj = seatLayoutCopy[parentIndex][childIndex];
    if (seatObj.seatType === "Covid19") {
      dispatch(setPopupInfo({ open: true, type: "COVID-19" }));
      return true;
    }

    if (seatObj.seatType === "Companion" &&  !seatObj.isSelected &&!infoPopup.data.event) {
      dispatch(setPopupInfo({ open: true, type: "Companion" , data: {companion: false, event, parentIndex, childIndex}}));
      return;
    }

    let seatGap = false;

    seatObj["isBooked"] = false;

    if (seatObj.seatType === "Aisle") {
      return true;
    }
    if (seatObj.seatType === "Empty") {
      seatGap = true;
    }

    if ((!seatObj.isBooked && !seatObj.isAvailable) || seatObj.isBooked) {
      return false;
    }

    if (seatObj.isSelected) {
      seatObj.isSelected = false;
      setCount(count - 1);
      seatSelected.splice(
        seatSelected.findIndex((val) => val.SeatId === seatObj.seatId),
        1
      );
    } else if (event) {
      seatObj["isBooked"] = true;
      const updateCount = count + 1;
      setCount(updateCount);
      seatObj.isSelected = true;
      seatSelected.push({
        SeatId: seatObj.seatId,
        AreaNumber: seatObj.areaNumber,
        RowIndex: seatObj.row,
        ColumnIndex: seatObj.column,
        AreaCategoryCode: ticketBooking.ticketType[0].AreaCategoryCode,
        ParentIndex: parentIndex,
        ChildIndex: childIndex,
      });
      seatLayoutCopy[parentIndex][childIndex] = seatObj;
    }
    setSelectedSeat(seatSelected);
    setSeatLayout(seatLayoutCopy);

    if (seatObj.seatType === "Special" && seatObj.isSelected) {
      dispatch(setPopupInfo({ open: true, type: "Special" }));
    }
  };

  const onNext = async () => {
    setErrorMessage("");
    if (selectedSeat.length === 0) {
      setErrorMessage("No Seats Selected");
      return;
    }
    dispatch(setUpModalHeaderText("Add Food & Drink"));
    if (
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
    ) {
      let totalTicket = ticketBooking.ticketDetails?.ticketTypes?.reduce(
        (acc: number, v: ITicketType) => (v.isPackageTicket?(acc+(v.qty! * v?.isPackageContent?.Quantity!)):(acc + v.qty!)),
        0
      )!;

      if (totalTicket - selectedSeat.length > 0) {
        setErrorMessage(
          "Out of " +
            totalTicket +
            " seats " +
            (totalTicket - selectedSeat.length) +
            " are not booked"
        );
        return;
      }
    }

    if (settings.enableSeatGap === 1) {
      const checkSeatError = checkSeat(selectedSeat, seatLayout);
      if (checkSeatError.error === 1) {
        setErrorMessage(
          "You have left a single seat gap on the aisle, please adjust your seating choice so no gaps remain."
        );
        return;
      }
    }

    const validateSeat = checkSeatValidation(selectedSeat, seatLayout);
    if(validateSeat && !validateSeat.status){
      setErrorMessage(validateSeat.message);
      return;
    }

    const payload = {
      cinemaId: films?.cinemaId,
      countryId: films?.countryId,
      hasGL: ticketBooking.hasGL,
      hasSoho: films.sessionAttributes === "Soho" ? 1 : 0,
      isPremium: PREMIUM_SERCHRG_ATTR[brandId].includes(films.sessionAttributes)?1:0,
      isMember: isMember,
      excludeBookingFee: EXCLUDE_BOOKING_FEE_SESSION.includes(films?.sessionAttributes?.toLowerCase())?1:0,
      day: moment.parseZone(films?.showDateTime).format("dddd"),
      movieId: films?.movieId,
      requestType: API_REQUEST_TYPE.ADD_TICKET,
      reservedSeating: 1,
      selectedSeats: selectedSeat,
      sessionId: films?.sessionId,
      ticketType: ticketBooking.ticketType[0].Description,
      ticketTypes:
        modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
        modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
          ? ticketBooking.ticketDetails?.ticketTypes?.map((v: ITicketType) => {
              return { ...v, Description: v.description ? v.description : "" };
            })
          : ticketBooking.ticketType.map((v: ITicketType) => {
              const value = {
                Description: v.Description ? v.Description : "",
                qty: selectedSeat.length,
                bookingFees:
                calculateServiceCharge({
                  hasGL,
                  brandId,
                  films,
                  serviceCharge: serviceChargeConfig,
                  isMember
                }) * selectedSeat.length,
                count: selectedSeat.length,
                price: v.Price,
                ticketTypeCode: v.TicketTypeCode,
              };
              return value;
            }),
      userDetials:
        userDetails && userDetails?.result?.memberRefId!
          ? formatUserDetails(userDetails, countryId, films?.cinemaId!)
          : {},
      userSessionId: ticketDetails?.userSessionId,
      isMobile: 0,
      userAgent: navigator.userAgent,
    };

    const ticketType: TICKET_FLOW_TYPES = modalType;
    const next: MODAL_TYPE = nextModal;
    const modal = TICKET_FLOW[ticketType][next];

    const ticketResponse = await addTicketApi(payload);
    const {
      data: {
        data: { error, saleId },
      },
    } = ticketResponse;
    if (error) {
      toast.error(
        error === "SeatsUnavailable"
          ? "There was an error in booking your ticket(s): Seat not available"
          : "There was an error in booking your ticket(s)"
      );
      return;
    }

    dispatch(
      addTicketSuccess({
        total,
        bookingFees,
        price,
        reservedSeating: payload.reservedSeating,
        selectedSeats: payload.selectedSeats,
        ticketTypes: payload.ticketTypes ? payload.ticketTypes : [],
        userSessionId: saleId,
      })
    );
    dispatch(setModal({ ...modal, type: modalType }));
  };

  const updateMemberdetails = async(userSessionId: string)=>{
    if (
      isLoggedIn &&
      !ticketLessFb &&
      userDetails?.cardNumber &&
      (brandId === BRANDS.AU ||
        (brandId === BRANDS.US &&
          ALLOWED_MEMBER_VALIDATE.includes(currentCinema?.slug)))
    ) {
      const memberResponse: any = await memberValidationApi({
        MemberRefId: userDetails?.vista_memberid,
        UserSessionId: userSessionId,
        cardNumber: userDetails?.cardNumber,
        countryId: countryId,
      });

      if (memberResponse.status && memberResponse.response.data && memberResponse.response.data.data) {
        dispatch(memberValidationSuccess(memberResponse.response.data.data));
      }
    }
  }

  const onPrev = async () => {
    if (
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB
    ) {
      if (ticketDetails && ticketDetails.userSessionId) {
        dispatch(
          cancelOnlyTicketAction({
            countryId: countryId,
            requestType: "cancelOrder",
            userSessionId: ticketDetails.userSessionId,
          })
        );
        const userSessionId = generateUsersessionId(currentCinema?.slug!);
        dispatch(updateSession(userSessionId));
        updateMemberdetails(userSessionId);
      }
      // await dispatch(removeFoodItemsCart());
      // await dispatch(clearFoodItemsAction());
      const ticketType: TICKET_FLOW_TYPES = modalType;
      const prev: MODAL_TYPE = prevModal;
      const modal = TICKET_FLOW[ticketType][prev];
      dispatch(
        setModal({
          ...modal,
          type: modalType,
        })
      );
    } else {
      onCancel();
    }
  };


  const isSeatSwap = useMemo(() => {
    return pathname.includes("/seat-swap/") && seatSwapDetails ? true : false;
  }, [pathname, seatSwapDetails]);

  const legend = useMemo(()=>{
    return seatLayout ? [...new Set(seatLayout.flat().map(v=>v.seatType))]:[]
  },[seatLayout])

  const totalTicketQty = useMemo(() => {
    if (ticketBooking?.ticketDetails?.ticketTypes) {
      return ticketBooking?.ticketDetails?.ticketTypes.reduce((acc: number, v: IFormatedTicketType) => {
        acc = acc + (v.isPackageTicket?v.qty*v.isPackageContent.Quantity:v.qty)
        return acc;
      }, 0)
    } else {
      return 0
    }
  }, [ticketBooking])

  return (
    <div className={`row seat-selection-wrapper seat-selection-${brandId}`}>
      <MovieDescription />
      {settings.enableTicketBooking == 0 ? (
        <div className="col-md-8 bookingnotallowed">
          Online ticket booking not available
        </div>
      ) : (
        <div className="col-12 col-md-12 col-lg-8 p-l-10">
          {modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING &&
          modalType !== TICKET_FLOW_TYPES.MULTI_TICKETING_FB &&
          sharedUserSession &&
          sharedUserSession.length > 0 ? (
            <div className="col-12 socialSharing">
              <span>{sharedUserSession[0].userName}</span> is sharing their
              seats with you!
            </div>
          ) : null}
          {isSeatSwap && modalType === TICKET_FLOW_TYPES.SEAT_SELECTION ? (
            <SwapFilter />
          ) : isSeatSwap ? (
            <div>
              <h5 className="mb-4">Select seats to swap to:</h5>
            </div>
          ) : null}
          <div className="whole-seats">
            <div className="seats">
              <ul>
                {legend.includes('Empty')?<li className="avilable">Available</li>:null}
                {legend.includes('Broken') || legend.includes('Sold')?<li className="unavilable">Unavailable</li>:null}
                <li className="selected">Selected</li>
                {legend.includes('Special')?<li className="ada">ADA Accessible</li>:null}
                {legend.includes('Companion')?<li className="companion">Companion Seat</li>:null}
                {sharedUserSession && sharedUserSession.length > 0 ? (
                  <li className="seat-shared">Shared</li>
                ) : null}
                {covidSeatStatus ? (
                  <li className="covid">Covid-19 Seat Gap</li>
                ) : null}
              </ul>
            </div>
            <div className="screen-image">
              <img
                className="w-100"
                src={
                  brandId === BRANDS.US ? URLS.SCREEN_VIEW_2 : URLS.SCREEN_VIEW
                }
                alt="Screen"
                title="Screen"
              />
            </div>
            <div className="seat_layout_wrap">
              <div className="seat_layout">
                <div
                  className="mCustomScrollbar custom_scroll_content"
                  id="seatSelectionLayoutScrollbar"
                >
                  <table aria-describedby="seat selection">
                    <thead>
                      {seatLayout.map((seatRowObj, i) => (
                        <tr>
                          {seatRowObj.map((seatObj, j) => (
                            <th id={`head_${j}`}></th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody>
                      {seatLayout.map((seatRowObj, parentIndex) => (
                        <tr>
                          {seatRowObj.map((seatObj, first) => (
                            <>
                              {countryId === COUNTRY.STA && first === 0 ? (
                                <td className="cust-ticker-order-right">
                                  {seatObj.PhysicalName}
                                </td>
                              ) : null}
                            </>
                          ))}
                          {seatRowObj.map((seatObj, childIndex) => (
                            <td>
                              <input type="checkbox" className="checkbox-cls" />
                              <span
                                className={`checkmark
                        ${
                          !seatObj.isBooked &&
                          !seatObj.isAvailable &&
                          seatObj.seatType != "Broken" &&
                          seatObj.seatType != "Covid19"
                            ? "empty"
                            : ""
                        }
                        ${
                          seatObj.isBooked && !seatObj.isAvailable
                            ? "in-active"
                            : ""
                        }
                        ${seatObj.isSelected ? "active" : ""}
                        ${
                          seatObj.isAvailable && seatObj.seatType == "Special"
                            ? "wheel-chair"
                            : ""
                        }
                        ${seatObj.seatType == "Companion" ? "companion" : ""} 
                        ${seatObj.seatType == "ada" ? "ada" : ""} 
                        ${seatObj.seatType == "Covid19" ? "covid19" : ""}
                        ${
                          !seatObj.isAvailable &&
                          !seatObj.isBooked &&
                          seatObj.seatType == "Broken"
                            ? "broken"
                            : ""
                        }               
                        ${
                          !seatObj.isAvailable &&
                          !seatObj.isBooked &&
                          seatObj.seatType == "Placeholder"
                            ? "placeholder"
                            : ""
                        }
                        ${
                          sharedUserSession &&
                          sharedUserSession.length > 0 &&
                          sharedUserSession.find((v: ISharedUserSession) =>
                            v.seats.split(",").includes(seatObj.seatId!)
                          )
                            ? "shared-seat"
                            : ""
                        }
                       
                        `}
                                onClick={() =>
                                  onClickSeat(
                                    !seatObj["isSelected"],
                                    parentIndex,
                                    childIndex
                                  )
                                }
                              ></span>
                            </td>
                          ))}
                          {seatRowObj.map((seatObj, last) => (
                            <>
                              {countryId === COUNTRY.STA &&
                              last === seatRowObj.length - 1 ? (
                                <td className="cust-ticker-order-left">
                                  {seatObj.PhysicalName}
                                </td>
                              ) : null}
                            </>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {errorMessage ? (
              <div className="error_message">
                <span>{errorMessage}</span>
              </div>
            ) : null}
            {seatLayout[0] && seatLayout[0].length > 16 ? (
              <div className="scroll-seats">Scroll to see more seats</div>
            ) : null}

            {sharedUserSession && sharedUserSession.length > 0 ? (
              <div className="seat_count">
                <div className="seatnames_list">
                  <ul>
                    <li>{sharedUserSession[0].userName}'S SEATS :</li>
                    {sharedUserSession.map((v: ISharedUserSession) => (
                      <li className="m-0">{v.seats}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ) : null}

            <div className="seat_count">
              <div className="seatnames_list">
                {selectedSeat.length > 0 ? (
                  <ul>
                    <li>
                      {sharedUserSession && sharedUserSession.length > 0
                        ? "YOUR "
                        : ""}
                      SEATS :
                    </li>
                    {selectedSeat.map((v) => (
                      <li>{v.SeatId}</li>
                    ))}
                  </ul>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form_page">
            <div className="user-pay-details">
              <div className="col-md-12">
                {isLoggedIn && brandId === BRANDS.AU ? (
                  <>
                    <div className="welcome"> Welcome back </div>
                    <div className="my-account text-capti font-size-16">
                      {userDetails?.firstName}
                    </div>
                  </>
                ) : null}
                {isSeatSwap ? (
                  <div className="user-details">
                    <div className="row">
                      <div className="col-12 col-md-12">
                        <div className="total">REFUND TOTAL</div>
                        <div className="total-amount">
                          ${(seatSwapDetails.totalAmount / 100).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="user-details">
                  <div className="row">
                    <div className="col-12 col-md-12">
                      <div className="total">
                        {isSeatSwap ? "NEW TOTAL" : "TOTAL ORDER"}
                      </div>
                      <div className="total-amount">${total.toFixed(2)}</div>
                      {modalType === TICKET_FLOW_TYPES.MULTI_TICKETING ||
                      modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB ? (
                        <>
                          {ticketBooking?.ticketDetails?.ticketTypes?.map(
                            (v: IFormatedTicketType) =>
                              v.qty > 0 ? (
                                <div>
                                  <div className="total-det">
                                    {v.qty} x {v.description.replace(/\n/i,'').replace(/^.*BOGO.*$/i,'BOGO Admissions')} SEATS: $
                                    {(v.qty * v.price).toFixed(2)}
                                  </div>
                                </div>
                              ) : null
                          )}
                        </>
                      ) : selectedSeat.length > 0 ? (
                        <div className="total-det">
                          {selectedSeat.length} SEATS: $
                          {Number(ticketBooking?.ticketType[0]?.Price).toFixed(
                            2
                          )}
                        </div>
                      ) : null}
                      {totalTicketQty > 0?<div className="total-det">
                        <span>{totalTicketQty} x BOOKING FEE : ${bookingFees.toFixed(2)}</span>
                      </div>: null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 movie_footer_btn_el">
                <div className="movie_button_wrap">
                  <Button classNames="m-r-10" onClick={onPrev} text={(modalType === TICKET_FLOW_TYPES.MULTI_TICKETING || modalType === TICKET_FLOW_TYPES.MULTI_TICKETING_FB)?'Back':'Cancel'} type="outline" />
                  <Button iconWidth={10} iconHeight={20} onClick={onNext} text= "Next" type="fill" iconPosition = "post" icon={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}arrow_right_white.svg`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeatSelection;
